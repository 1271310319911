import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useState } from 'react';


// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// react icons
import { BsFillLightningFill } from "react-icons/bs";

// utils
import axios, { endpoints } from 'src/utils/axios';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useSnackbar } from 'src/components/snackbar';
//
import { constants } from 'src/utils/constant';
import { decryptToken } from 'src/utils/common';
import { Button } from '@mui/material';
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';



// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate=useNavigate()
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [mainData, setMainData] =useState([])

  const handleLive = async() => {
    const user = JSON.parse(decryptToken(localStorage.getItem(constants.keyUserData)))
    // const token = decryptToken(localStorage.getItem(constants.keyCustomerToken));
    // console.log(user)

    const data1 = localStorage.getItem('savedData')
    // console.log("data1", data1)

    try {
      const userName = user.userName || "";
       const response = await axios.post(endpoints.user.templateData, {userName });
       // console.log("response",response)
      setMainData( JSON.parse(response?.data.data.miniwebsite.data))
      // console.log('maindata', mainData.length > 0)

     } catch (error) {
       console.error(error)
       
     }

     if(data1 || mainData.length > 0){
          if (user.userName) {
          if(process.env.DEBUG === 'true') {
            window.open(`http://${user.userName}.localhost:8002`, '_blank');
          }
          else {
            window.open(`https://${user.userName}.thisside.in`, '_blank');
          }
        } else {
          enqueueSnackbar('Please select a username.', {
            variant: 'info',
          });

          // return;
          navigate('/customer/dashboard/profile');
        }
     }else{
        enqueueSnackbar('Please save your data.', {
          variant: 'info',
      });


     }


   
  

    
    
  }

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        <Button
          sx={{  ml: 1 }}
           className=" w-1/4 md:w-32"
          color="primary"
          variant="outlined"
          onClick={handleLive}
        >
         <BsFillLightningFill color='#EBCB68' style={{marginRight:'8px'}} />
         Live
        </Button>

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
